// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output" ]

  connect() {
  }
  toggleMenu(e) {
    console.log("Menu click");
    try {
      document.getElementById('pycmenu').classList.toggle("hidden");
      document.getElementById('pycmenu').classList.toggle("opacity-0");
      document.getElementById('pycmenu').classList.toggle("opacity-100");
    } catch (e) {};
    
  }
}
