import Carousel from "stimulus-carousel"
import Swiper from 'swiper/bundle'
    
export default class extends Carousel {
  connect() {
    super.connect()


    this.swiper = new Swiper(this.element, {
      ...this.defaultOptions,...this.optionsValue,
      on: {
        init: function () {
          var el = this.slides[this.activeIndex].children[0];
          var aclass = el.dataset.animation
          el.classList.add(aclass)
        },
        slideChangeTransitionStart: function () {
          var el = this.slides[this.activeIndex].children[0];
          var aclass = el.dataset.animation
          el.classList.add(aclass)
        },
        slideChangeTransitionEnd: function () {
          var el = this.slides[this.previousIndex].children[0];
          var aclass = el.dataset.animation
          el.classList.remove(aclass)
        }
      },
      //onAny(eventName, ...args) {
        //console.log("Event",eventName);
        //console.log("Data",args);
      //}
    });
    // Default options for every carousels.
    this.defaultOptions
  }

  // You can set default options in this getter.
  get defaultOptions () {
    return {
      // Your default options here
    }
  }
}
