// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

global.jQuery=global.$ = require('jquery')

import 'lightbox2/dist/css/lightbox.min.css'

document.lightbox = require("lightbox2")




import "controllers"

import "stylesheets/application"

import 'swiper/swiper-bundle.min.css'


import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

document.AOS = AOS


